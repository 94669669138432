import { ConnectorSyncState } from '@outmind/types';
import axios from 'axios';
import { useQuery, useQueryClient, UseQueryResult } from 'react-query';

import { ApiQuery, useApiRouteMaker } from '../../useApi';
import { useConnectors } from '../useConnectors';
import { useLocalDiskConnectorStates } from '../useLocalDiskConnectorStates';

/**
 * Retrieves all the connectors sync states
 */
export const useConnectorsSyncStates = (): UseQueryResult<Record<string, ConnectorSyncState>> => {
  const { data: connectors = [] } = useConnectors();

  useLocalDiskConnectorStates();

  const queryClient = useQueryClient();

  const makeRoute = useApiRouteMaker(ApiQuery.GET_CONNECTOR_SYNC_STATE);

  const getConnectorSyncStateQuery = useQuery<Record<string, ConnectorSyncState>>(
    [ApiQuery.GET_CONNECTOR_SYNC_STATE],
    async () => {
      const route = makeRoute();

      const result = await axios({
        method: route.method,
        params: {
          connectorsIds: connectors.map((connector) => connector.id),
        },
        url: route.url,
        withCredentials: true,
      });

      const syncStates = queryClient.getQueryData<Record<string, ConnectorSyncState>>([
        ApiQuery.GET_CONNECTOR_SYNC_STATE,
      ]);

      return { ...syncStates, ...result.data };
    },
    {
      refetchInterval: 10 * 1000, // Refetch every 10 seconds
      refetchIntervalInBackground: false, // Disable interval fetching when window is not focuced
    },
  );

  return getConnectorSyncStateQuery;
};
